$(function () {
	//Кастомизация чекбоксов
	init_checkbox_style();
	init_checkbox_swicher();
	init_radio_style();
	init_tel_mask();


	$('.js-form-validate').each(function () {
		if ($(this).hasClass('js-form-validate-no-disable')) {
			return;
		}

		if (form_validate($(this), false)) {
			$('.js-form-validate-btn').removeClass("disabled");
		} else {
			$('.js-form-validate-btn').addClass("disabled");
		}
	});


	$('.js-form-validate').find('select,input,textarea').on('input', function () {
		if ($('.js-form-validate').hasClass('js-form-validate-no-disable')) {
			return;
		}

		$(this).closest('.input').removeClass("error")

		if (form_validate($(this).closest('form'), false)) {
			$('.js-form-validate-btn').removeClass("disabled");
		} else {
			$('.js-form-validate-btn').addClass("disabled");
		}

	});
	$('.js-form-validate').find('select,input,textarea').on('change', function () {
		if ($('.js-form-validate').hasClass('js-form-validate-no-disable')) {
			return;
		}

		if (form_validate($(this).closest('form'), false)) {
			$('.js-form-validate-btn').removeClass("disabled");
		} else {
			$('.js-form-validate-btn').addClass("disabled");
		}

	});

	$('.js-form-validate-btn').on('click', function (e) {
		if (form_validate($('.js-form-validate'), true) === false) {
			e.stopImmediatePropagation();
			e.stopPropagation();
			return false;
		}
	});


});


const init_tel_mask = () => {
	if(!IMask) return;

	$('[type="tel"]').each((k, elem) => {
		let mask = '+7 (000) 000 00 00'
		IMask(
			elem, {
				mask: mask,
				placeholderChar: '_'
			});

		$(elem).on("input", (e) => {
			let self = e.target;

			if ($(self).val().length < mask.length) {
				$(self).closest('.input,.input-modal').removeClass("phone-complete");
			} else {
				$(self).closest('.input,.input-modal').addClass("phone-complete");
				$(self).closest('.input,.input-modal').removeClass("error")
			}
		});
	});

}


function form_validate(form, show_error = true) {
	let error = false,
		requireds = form.find('.required input, .required select, .required textarea');

	requireds.each(function () {
		let hint = $(this).siblings('.input-hint');
		if (!check_input($(this), show_error)) {
			error = true;
			if (show_error) {
				hint.text('Обязательное поле');
			}
		}
	});

	let ava = form.find('.gallery-itm-img');
	if (ava.length > 0) {
		let change = ava.find('.change-avatar.empty');
		if (change.length > 0 && change.hasClass('empty')) {
			error = true;
			if (show_error) {
				change.addClass('error');
			}
		}
	}

	let employer = form.find('.js-employer-table');
	if (employer.length > 0) {
		if (employer.find('tr[data-id]').length === 0) {
			return false;
		}
	}

	return !error;
}

function check_input(input, show_error = false) {
	let parent = input.closest(".input"),
		hint = parent.find('.input-hint')

	if (input.closest('.hide').length > 0) {
		return true;
	}

	if (show_error && input.attr('type') !== 'email') {
		hint.text('');
		parent.removeClass("error");
	}

	if ((input.attr('type') === 'checkbox') && input.prop('checked') === false) {

		if (input.hasClass('js-required-checkbox-group')) {
			if (input.closest('.required').find('.js-required-checkbox-group:checked').length > 0) {
				return true;
			}
		}

		if (show_error) {
			hint.text('Поле обязательно для заполнения');
			parent.addClass("error");
		}
		return false;
	}

	if (input.attr('type') === 'radio') {
		let name = input.attr('name');

		if ($('input[name="' + name + '"]:checked').length === 0) {
			return false;
		}
	}

	if (input.attr('type') === 'file' && input.hasClass('js-input-avatar')) {
		let block = input.closest('.change-avatar');
		if (!block.hasClass('empty')) {
			return true;
		}
	}

	if (input.val().length <= 0) {
		if (show_error) {
			hint.text('Поле обязательно для заполнения');
			parent.addClass("error");
		}

		return false;
	}

	if (input.attr('type') === 'email') {
		let is_email = input.val().match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);

		if (is_email == null && input.val().length > 0) {
			hint.text('Неверно заполнен e-mail');
			parent.addClass("error");
			return false;
		} else {
			hint.text('');
			parent.removeClass("error");
		}
	}

	if (input.attr('type') === 'tel') {
		if (!parent.hasClass("phone-complete")) {
			if (show_error) {
				hint.text('Поле обязательно для заполнения');
				parent.addClass("error");
			}
			return false;
		}
	}

	return input.val().length !== 0;

}


function form_start_validate(form) {
	let error = false,
		requireds = form.find('.required input, .required textarea');

	requireds.each(function () {
		if (!check_input($(this))) {
			error = true;
		}
	});

	return error;
}

function init_radio_style() {
	$('input.js-radio').each(function () {
		if (!$(this).parent().is('.radio_box')) {
			var placeholder = $(this).attr('placeholder');

			$(this).wrap('<label class="radio_box"></label>');
			$(this).after('<span class="radio_box__item"></span><span class="radio_box__text">' + placeholder + '</span>');
		}

		if ($(this).prop('checked')) {
			$(this).parent('.radio_box').addClass('checked');
		} else {
			$(this).parent('.radio_box').removeClass('checked');
		}
	});
}

function init_checkbox_style() {
	$('input.js-checkbox').each(function () {
		if (!$(this).closest('.check_box').length > 0) {
			var placeholder = $(this).attr('placeholder');

			$(this).wrap('<label class="check_box"></label>');
			$(this).after('<span class="check_box__item"></span><span class="check_box__text">' + placeholder + '</span>');

			if ($(this).prop('disabled')) {
				$(this).parent().addClass("disabled");
			}
		}

		if ($(this).prop('checked')) {
			$(this).parent('.check_box').addClass('checked');
		} else {
			$(this).parent('.check_box').removeClass('checked');
		}
	});
}

function init_checkbox_swicher() {
	$('input.js-checkbox-switcher').each(function () {
		if (!$(this).parent().is('.switcher')) {
			var placeholder = $(this).attr('placeholder');

			$(this).wrap('<label class="switcher"></label>');
			$(this).after('<span class="switcher__text">' + placeholder + '</span><span class="switcher__item"></span>');

			if ($(this).prop('disabled')) {
				$(this).parent().addClass("disabled");
			}
		}

		if ($(this).prop('checked')) {
			$(this).parent('.check_box').addClass('checked');
		} else {
			$(this).parent('.check_box').removeClass('checked');
		}
	});
}