gsap.registerPlugin(ScrollTrigger)


$(() => {
	header_menu_nav()
	title_animate_init()
	check_anchor();
})

setTimeout(() => {
	header_menu_nav()
}, 100)

new Swiper('.bonus-slider', {
	speed: 800,
	loop: true,
	slidesPerView: 1,
	centeredSlides: true,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		800: {
			slidesPerView: 3
		}
	}
});

function header_menu_nav() {
	let active = $('.header-menu .active'),
		nav = $('.header-menu-nav')

	if (active.length === 0) return;

	nav.css({
		width: active.outerWidth(),
		left: active.position().left
	})

}


$('.header-menu a').on('mouseenter', (e) => {
	let active = $(e.currentTarget),
		nav = $('.header-menu-nav')

	if (active.length === 0) return;

	nav.css({
		width: active.outerWidth(),
		left: active.position().left
	})

})

$('.header-menu a').on('mouseleave', (e) => {
	header_menu_nav()
})

$('.js-anchor').on('click', (e) => {
	let self = $(e.currentTarget),
		section = $(self.attr('href'))

	$('body,html').animate({
		scrollTop: section.offset().top - $('.header').outerHeight(true) - 50
	}, 800)
})

$(window).on('scroll', (e) => {
	let scroll = $(window).scrollTop() + $('.header').outerHeight(true) + 60

	$('.header-menu .js-anchor').each((k, i) => {
		let href = $(i).attr('href')

		if (href[0] !== '#') return

		let section = $(href)

		if (scroll > section.offset().top && scroll < section.offset().top + section.outerHeight(true)) {
			$('.header-menu .js-anchor').removeClass("active")
			$(i).addClass("active")
			header_menu_nav()
		}
	})

})

function title_animate_init() {
	$('.widget-title').each((k, i) => {
		let words = $(i).text().split(" ")
		$(i).html("");
		words.forEach((el, index) => {
			$(i).append(`<span class="word-animate">${el}</span> `)
		})

		gsap.fromTo($(i).find(".word-animate"), {
			y: 40,
		}, {
			y: 0,
			duration: .5,
			stagger: .15,
			scrollTrigger: {
				trigger: i,
				start: 'top bottom',
				end: '+=400px',
			}
		})

	})

}

const check_anchor = () => {
	let load_anchor = window.location.hash;
	if (!load_anchor) return false;

	let target = $(load_anchor)
	if (!target) return false;

	$('body,html').scrollTop(target.offset().top - $('.header').outerHeight(true) - 50)
}
