var is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;

$(function () {
	check_header()

	$(window).on('scroll', (e) => {
		check_header()
	})

	$(window).on('resize', function () {
		is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;
	});


	$(document).on('click', '.js-open-modal-ajax', (e) => {
		let self = $(e.currentTarget),
			id = self.attr('data-id')

		e.preventDefault();

		close_ajax_modal();
		open_ajax_modal(self.attr('href'), id)
	});

	$(document).on('click', '.js-close-modal-ajax', (e) => {
		close_ajax_modal();
	});

	$(document).on('click', '.js-open-modal', function () {
		let href = $(this).attr('href'),
			id = $(this).attr('data-id')

		open_modal(href, id);
	});

	$('.js-close-modal').on('click', function () {
		$(this).closest('.modal').removeClass("active");

		return false;
	});

	$('.js-close-modal-all').on('click', function () {
		$('.modal').removeClass("active");

		return false;
	});

	$('.js-mobile-menu-btn').on('click', (e) => {
		let self = $(e.currentTarget)

		$('.mobile-menu,.mobile-menu-fade,.mobile-gamburger .gamburger').toggleClass('active')
	})


});



function form_reset(form) {
	form.find('input,textarea').val("")
}


function check_header() {
	let scroll = $(window).scrollTop()

	if (scroll > 50) {
		$('.header').addClass("header--fixed")
	} else {
		$('.header').removeClass("header--fixed")
	}
}

function open_modal(href, id = 0) {
	console.log(href)
	console.log($(href))
	$(href).addClass("active");
	$(href).attr('data-id', id);
}

const open_ajax_modal = (url, id) => {
	let modal_type = "modal--right"
	//preloader_show();
	setTimeout(function () {
		$.ajax({
			url: url,
			data: {'id': id},
			dataType: "html",
			success: function (response) {
				$('body').append(
					'<div class="modal ' + modal_type + ' js-ajax-modal">' +
					'<div class="modal-fade js-close-modal-ajax"></div>' +
					'<div class="modal-content"><div class="modal-close cross js-close-modal-ajax"></div>' +
					'<div class="modal-content-scroll">' +
					response +
					'</div>' +
					'</div>' +
					'</div>'
				)

				svgInit();

				setTimeout(function () {
					$('.js-ajax-modal').addClass("active");
					scrollBlock();
					preloader_hide();
				}, 100)
			},
			error: function () {
				alert("error!");
			},
		});
	}, 500)

}

const close_ajax_modal = () => {
	let modal = $('.js-ajax-modal')
	modal.removeClass("active");
	scrollUnBlock();

	setTimeout(function () {
		modal.remove();
	}, 300)
}